import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Container, Modal, Alert, Button } from 'react-bootstrap';
import { FaCheckCircle, FaEdit, FaTrashAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TaskManager.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

const TaskManager = () => {
  const [tasks, setTasks] = useState(() => {
    const savedTasks = localStorage.getItem('tasks');
    return savedTasks ? JSON.parse(savedTasks) : [];
  });

  const [predefinedItems, setPredefinedItems] = useState(() => {
    const savedItems = localStorage.getItem('predefinedItems');
    return savedItems
      ? JSON.parse(savedItems)
      : ['Delivery Note', 'Pick and Pack Label', 'New Flight', 'Checklist', 'Flight-Change', 'Food Label', 'Seals and report','BRML','PZML','TAML','HAML',' VTML','Others'];
  });

  const [persons, setPersons] = useState(() => {
    const savedPersons = localStorage.getItem('persons');
    return savedPersons
      ? JSON.parse(savedPersons)
      : ['Camila', 'Oran', 'Joe', 'Ben', 'Bente', 'Chalanda', 'Uma', 'Others'];
  });

  const [flightNumber, setFlightNumber] = useState('');
  const [flightDate, setFlightDate] = useState(new Date());
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [newEntry, setNewEntry] = useState('');
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editTarget, setEditTarget] = useState('');

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
    localStorage.setItem('predefinedItems', JSON.stringify(predefinedItems));
    localStorage.setItem('persons', JSON.stringify(persons));
  }, [tasks, predefinedItems, persons]);

  const toggleSelection = (item, list, setList) => {
    if (item === 'Others') {
      setModalType(list === selectedItems ? 'item' : 'person');
      setShowModal(true);
      return;
    }
    if (list.includes(item)) {
      setList(list.filter((i) => i !== item));
    } else {
      setList([...list, item]);
    }
  };

  const addNewEntry = () => {
    if (newEntry.trim() === '') {
      setError('Entry cannot be empty.');
      return;
    }

    if (modalType === 'item') {
      setPredefinedItems((prev) => [...prev.filter((i) => i !== 'Others'), newEntry, 'Others']);
      setSelectedItems((prev) => [...prev, newEntry]);
    } else if (modalType === 'person') {
      setPersons((prev) => [...prev.filter((p) => p !== 'Others'), newEntry, 'Others']);
      setSelectedPersons((prev) => [...prev, newEntry]);
    }

    setNewEntry('');
    setShowModal(false);
    setError('');
  };

  const addTask = () => {
    if (!flightNumber || !flightDate || selectedItems.length === 0 || selectedPersons.length === 0) {
      setError('Please fill all fields and select at least one item and one person.');
      return;
    }

    const newTask = {
      id: Date.now(),
      flightNumber,
      flightDate: formatDate(flightDate),
      createdAt: new Date().toISOString(),
      items: selectedItems,
      persons: selectedPersons,
      done: false,
    };

    setTasks((prev) => [...prev, newTask]);
    setFlightNumber('');
    setFlightDate(new Date());
    setSelectedItems([]);
    setSelectedPersons([]);
    setError('');
  };

  const toggleTaskDone = (id) => {
    setTasks((prev) =>
      prev.map((task) => (task.id === id ? { ...task, done: !task.done } : task))
    );
  };

  const deleteTask = (id) => {
    setTasks((prev) => prev.filter((task) => task.id !== id));
  };

  const toggleEditMode = (type) => {
    setEditMode(true);
    setEditTarget(type);
  };

  const saveEditedList = () => {
    setEditMode(false);
    setError('');
  };

  return (
    <Container fluid>
      <div className="task-input-container">
        <h5 className="text-primary text-center mb-3">Task List of Lancement Department</h5>
        <Form>
          {error && <Alert variant="danger">{error}</Alert>}
          <Row className="g-3">
            <Col md={3}>
              <Form.Group controlId="formFlightNumber">
                <Form.Label>Flight Number</Form.Label>
                <Form.Control
                  type="text"
                  value={flightNumber}
                  onChange={(e) => setFlightNumber(e.target.value)}
                  placeholder="Enter flight number (e.g., 12345)"
                />
              </Form.Group>
              <Form.Group controlId="formFlightDate" className="mt-3">
                <Form.Label>Flight Date</Form.Label>
                <DatePicker
                  selected={flightDate}
                  onChange={(date) => setFlightDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Items</Form.Label>
                  <FaEdit
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleEditMode('items')}
                  />
                </div>
                <div className="scrollable-multi-column items">
                  {predefinedItems.map((item, index) => (
                    <div
                      key={index}
                      className={`item ${selectedItems.includes(item) ? 'selected' : ''}`}
                      onClick={() => toggleSelection(item, selectedItems, setSelectedItems)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label>Persons</Form.Label>
                  <FaEdit
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleEditMode('persons')}
                  />
                </div>
                <div className="scrollable-multi-column persons">
                  {persons.map((person, index) => (
                    <div
                      key={index}
                      className={`item ${selectedPersons.includes(person) ? 'selected' : ''}`}
                      onClick={() => toggleSelection(person, selectedPersons, setSelectedPersons)}
                    >
                      {person}
                    </div>
                  ))}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={addTask}>
              Add Task
            </Button>
          </div>
        </Form>
      </div>

      <div className="task-list">
        <Row className="g-4 justify-content-start">
          {tasks.map((task, index) => (
            <Col key={task.id} lg={3} md={6}>
              <Card className={`task-card shadow-sm ${task.done ? 'done' : ''}`}>
                {task.done && (
                  <div className="done-banner">
                    <span>DONE</span>
                  </div>
                )}
                <div className="task-card-icons">
                  <FaCheckCircle
                    className="task-icon done-icon"
                    title={task.done ? 'Mark as Undone' : 'Mark as Done'}
                    onClick={() => toggleTaskDone(task.id)}
                  />
                  <FaTrashAlt
                    className="task-icon delete-icon"
                    title="Delete Task"
                    onClick={() => deleteTask(task.id)}
                  />
                </div>
                <Card.Body>
                  <Card.Text className="flight-number">
                    <strong>Flight Number:</strong> {task.flightNumber}
                  </Card.Text>
                  <Card.Text className="flight-date">
                    <strong>Flight Date:</strong> {task.flightDate}
                  </Card.Text>
                  <Card.Text className="items-section">
                    <strong>Items:</strong>
                    <div>
                      {task.items.map((item, index) => (
                        <span
                          key={index}
                          className={`task-item ${task.done ? 'done' : ''}`}
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </Card.Text>
                  <Card.Text>
                    <strong>Persons:</strong> {task.persons.join(', ')}
                  </Card.Text>
                </Card.Body>
                <div className="task-created-date-time">
                  <small>
                    <strong>Created:</strong> {formatDate(new Date(task.createdAt))}{' '}
                    {new Date(task.createdAt).toLocaleTimeString()}
                  </small>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Modal show={editMode || showModal} onHide={() => {
        setEditMode(false);
        setShowModal(false);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode
              ? `Edit ${editTarget === 'items' ? 'Items' : 'Persons'}`
              : `Add New ${modalType === 'item' ? 'Item' : 'Person'}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editMode ? (
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={5}
                value={editTarget === 'items' ? predefinedItems.join('\n') : persons.join('\n')}
                onChange={(e) => {
                  const updatedList = e.target.value.split('\n').filter((item) => item.trim() !== '');
                  if (editTarget === 'items') {
                    setPredefinedItems(updatedList);
                  } else {
                    setPersons(updatedList);
                  }
                }}
              />
            </Form.Group>
          ) : (
            <Form.Control
              type="text"
              placeholder={`Enter new ${modalType === 'item' ? 'item' : 'person'}`}
              value={newEntry}
              onChange={(e) => setNewEntry(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <>
              <Button variant="secondary" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={saveEditedList}>
                Save
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={addNewEntry}>
                Save
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TaskManager;
